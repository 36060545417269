import { createWebHistory, createRouter } from "vue-router";

const ComingSoon = () => import("../components/Pages/ComingSoon");

//const Home = () => import("../components/Pages/Home");
//const AboutPage = () => import("../components/Pages/AboutPage");
//const FaqPage = () => import("../components/Pages/FaqPage");
//const TermsConditionsPage = () => import("../components/Pages/TermsConditionsPage");
//const PrivacyPolicyPage = () => import("../components/Pages/PrivacyPolicyPage");
//const ErrorPage = () => import("../components/Pages/ErrorPage");
//const SSISMonitoringPage = () => import("../components/Pages/SSISMonitoringPage");
//const OMSMonitoringPage = () => import("../components/Pages/OMSMonitoringPage");
//const ContactPage = () => import("../components/Pages/ContactPage");

const routes = [
  {
    path: "/",
    name: "ComingSoon",
    component: ComingSoon,
    meta: {
      description:
        "Discover worldwide IT solutions, digital technology, and services from Crimson Globe for transformative digital experiences.",
      title: "Crimson Globe",
    },
  },
/*{
  path: "/about",
  name: "AboutPage",
  component: AboutPage,
  meta: {
    title: "About",
  },
},
{
  path: "/faq",
  name: "FaqPage",
  component: FaqPage,
  meta: {
    title: "FAQ",
  },
},
{
  path: "/terms-condition",
  name: "TermsConditionsPage",
  component: TermsConditionsPage,
  meta: {
    title: "Terms Condition",
  },
},
{
  path: "/privacy-policy",
  name: "PrivacyPolicyPage",
  component: PrivacyPolicyPage,
  meta: {
    title: "Privacy Policy",
  },
},
{
  path: "/:pathMatch(.*)*",
  name: "ErrorPage",
  component: ErrorPage,
  meta: {
    title: "404",
  },
},
{
  path: "/services/ssis-monitoring",
  name: "SSISMonitoringPage",
  component: SSISMonitoringPage,
  meta: {
    description:
      "Discover the power of proactive SSISDB monitoring solutions from Crimson Globe that empower your data integration processes.",
    title: "SSIS Monitoring",
  },
},
{
  path: "/services/oms-monitoring",
  name: "OMSMonitoringPage",
  component: OMSMonitoringPage,
  meta: {
    description:
      "Empower businesses with real-time insights. Explore Crimson Globe's proactive OMS monitoring solutions for enhanced operational efficiency.",
    title: "OMS Monitoring",
  },
},
{
  path: "/contact",
  name: "ContactPage",
  component: ContactPage,
  meta: {
    title: "Contact",
  },
},*/
];

const router = createRouter({
history: createWebHistory(),
linkExactActiveClass: "active",
routes,
scrollBehavior() {
  return { top: 0, behavior: "smooth" };
},
});

router.beforeEach((to, from, next) => {
const nearestWithDescription = to.matched
  .slice()
  .reverse()
  .find((r) => r.meta && r.meta.description);
const nearestWithTitle = to.matched
  .slice()
  .reverse()
  .find((r) => r.meta && r.meta.title);
if (nearestWithTitle) {
  document.title = nearestWithTitle.meta.title;
} else {
  document.title = "Crimson Globe";
}

if (nearestWithDescription) {
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", nearestWithDescription.meta.description);
} else {
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", "");
}

next();
});

export default router;
