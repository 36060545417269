<template>
  <PreLoader v-if="isLoading" />
  <div :class="themeClass">
    <router-view />
    <!--<div class="switch-box">
      <label id="switch" class="switch">
        <input type="checkbox" @click="toggleTheme" id="slider" />
        <span class="slider round"></span>
      </label>
    </div>-->
  </div>
  <BackToTop />
</template>

<script>
import PreLoader from "./components/Layouts/PreLoader";
import BackToTop from "./components/Layouts/BackToTop";

export default {
  name: "App",
  components: {
    PreLoader,
    BackToTop,
  },
  data() {
    return {
      isLoading: true,
      theme: {
        isDark: false,
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  },
  methods: {
    toggleTheme() {
      this.theme.isDark = !this.theme.isDark;
      localStorage.setItem("theme", JSON.stringify(this.theme));
    },
  },
  created() {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      this.theme = JSON.parse(storedTheme);
    }
  },
  computed: {
    themeClass() {
      return this.theme.isDark ? "theme-dark" : "theme-light";
    },
  },
};
</script>